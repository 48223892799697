const viewsConstants = {
    createEvent: {
        stepOne: "Step One",
        stepTwo: "Step Two",
    },
    forbidden: { forbidden: "You have no access to view this page" },
    notFound: {
        404: "404",
        goToDashboard: "Go to Dashboard",
        pageNotFound: "Page Not Found",
    },
    serverError: { serverError: "Sudden Server Error happened, Please try again later" },
    updateEvent: {
        eventDetails: {
            NoOverlaysNote: "No overlays uploaded in this category",
            eventLink: "Event Link",
            overlaysTitle: "Overlays Images",
            uploadingNote: "Aspect ratio will be detected automatically and assigned to portrait or landscape",
        },
        publicPage: {
            bannerImageTitle: "Banner Image",
            pageActionsTitle: "Page Actions",
        },
        shareDetails: { domainTitle: "Add Custom Domain" },
    },
};

export default viewsConstants;
