const inputsLabelsConstants = {
    aspectRatio: "Aspect Ratio",
    assignPhotographers: "Assign Photographers",
    backgroundColor: "Background Color",
    buttonsTextColor: "Buttons Text Color",
    confirmPassword: "Confirm Password",
    consentText: "Consent Text",
    content: "Content",
    copy: "Copy",
    copyEvent: "Copy Event",
    customDomain: "Custom Domain",
    date: "Date",
    domain: "Domain",
    domainHelper: "Example: selfiexmedia.com",
    email: "Email",
    emailBottomBodyText: "Email Bottom Body Text",
    emailCallToAction: "Email Call To Action",
    emailFromName: "Email From Name",
    emailSubject: "Email Subject",
    emailTopBodyText: "Email Top Body Text",
    enableDownload: "Allow guests to download photos",
    enablePrivate: "Private Gallery Link",
    enableShare: "Enable sharing buttons on public page",
    endDate: "End Date",
    event: "Duplicate Event Setting From",
    eventNameColor: "Event Name Color",
    eventTitle: "Event Title",
    facebookText: "Facebook Text",
    facebookTitle: "Facebook Title",
    firstName: "First Name",
    foregroundColor: "Foreground Color",
    landscape: "Landscape",
    lastName: "Last Name",
    link: "Link",
    message: "Message",
    name: "Name",
    ownerEmail: "Client Email",
    pageActionLabel: "Page Action Label",
    pageActionUrl: "Page Action Url",
    pageActionUrlHelper: "Example: https://www.selfiexmedia.com 'Add 3 actions by Max.'",
    password: "Password",
    phoneNumber: "Phone Number",
    portrait: "Portrait",
    search: "Search",
    selectGuests: "Select Guests",
    smsProvider: "SMS Provider",
    smsText: "SMS Text",
    startDate: "Start Date",
    templateName: "Template Name",
    textColor: "Text Color",
    title: "Title",
    titleOnly: "Title Only",
    topBodyText: "Top Body Text",
    twillio: "Twillio",
    twitterText: "Twitter Text",
    useTemplate: "Use Template",
};

export default inputsLabelsConstants;
