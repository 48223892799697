import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { globalData } from "data";

function Calendar({
    disabled,
    error,
    isEventCalendar,
    label,
    onChange,
    onKeyDown,
    value,
}) {
    const { calendarViews: calendarViewsData } = globalData;

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
                disabled={disabled}
                inputFormat={isEventCalendar ? "MM/dd/yyyy" : "MM/dd/yyyy hh:mm a"}
                label={label}
                openTo="day"
                value={value || null}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        error={error}
                        fullWidth
                        onKeyDown={onKeyDown}
                    />
                )}
                views={isEventCalendar ? calendarViewsData.slice(
                    0,
                    3,
                ) : calendarViewsData}
                onChange={onChange}
            />
        </LocalizationProvider>
    );
}

export default Calendar;
