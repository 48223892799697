const btnsNamesConstants = {
    activate: "Activate",
    add: "Add",
    archive: "Archive",
    archived: "Archived",
    assign: "Assign",
    cancel: "Cancel",
    clearFilters: "Clear Filters",
    copy: "Copy",
    copyEventLink: "Copy Event Link",
    copySlideshowLink: "Copy Slideshow Link",
    create: "Create",
    createTemplateFromEvent: "Create Template From Event",
    deactivate: "Deactivate",
    delete: "Delete",
    download: "Donwload",
    email: "Email",
    export: "Export Data",
    filter: "Filter",
    galleryActions: "Gallery Actions",
    generateZipFolder: "Generate Event Archive",
    login: "Login",
    openSlideshowPreview: "Open Slideshow Preview",
    phone: "Phone",
    remove: "Remove",
    resend: "Resend",
    save: "Save",
    send: "Send",
    setAsDefault: "Set as Default",
    shareSelected: "Share selected",
    shareToAll: "Share to all",
    unarchive: "Unarchive",
    unarchived: "Active",
    update: "Update",
    upload: "Upload",
};

export default btnsNamesConstants;
