import { rolesConstants } from "@constants";
import { Grid } from "@mui/material";
import { Controller } from "react-hook-form";

const {
    admin: adminRole,
    superAdmin: superAdminRole,
} = rolesConstants;

export const getFormData = (data) => {
    const formData = new FormData(); // eslint-disable-line

    // eslint-disable-next-line
    for (let key in data) {
        formData.append(
            key,
            data[key],
        );
    }

    return formData;
};

export const getSearchParams = (searchParams, startLimit) => {
    const pageSearchParam = searchParams.get("page") ? parseInt(searchParams.get("page") - 1) : 0;

    const limitSearchParam = parseInt(searchParams.get("limit")) || (startLimit || 10);

    const filterSearchParam = searchParams.get("filter") ? searchParams.get("filter") : "";

    const searchSearchParam = searchParams.get("search") ? searchParams.get("search") : "";

    const startDateSearchParam = searchParams.get("startDate") ? searchParams.get("startDate") : "";

    const endDateSearchParam = searchParams.get("endDate") ? searchParams.get("endDate") : "";

    const eventIdSearchParam = searchParams.get("eventId") ? searchParams.get("eventId") : "";

    return {
        endDate: endDateSearchParam,
        eventId: eventIdSearchParam,
        filter: filterSearchParam,
        limit: limitSearchParam,
        page: pageSearchParam,
        search: searchSearchParam,
        startDate: startDateSearchParam,
    };
};

export const getQuery = (
    page,
    limit,
    filter,
    search,
    startDate,
    endDate,
) => `page=${page + 1}&limit=${limit}${filter ? `&filter=${filter}` : ""}${search ? `&search=${search}` : ""}${startDate ? `&startDate=${startDate}` : ""}${endDate ? `&endDate=${endDate}` : ""}`;

export const getEventPhotosQuery = (startDate, endDate) => {
    let query = "";

    if (startDate) query = `?startDate=${startDate}`;

    if (endDate) query += `?endDate=${endDate}`;

    return query;
};

export const getFileNameFromUrl = (url) => {
    const { pathname } = new URL(url);

    const index = pathname.lastIndexOf("/");

    return !index ? pathname.substring(index + 1) : pathname;
};

export const checkAdminAuthorities = (data) => {
    const isAdmin = data?.user?.authorities?.map((authority) => authority?.authority === superAdminRole || authority?.authority === adminRole);

    return isAdmin?.includes(true);
};

export const getConvertedListData = (data, isEventGuestPhotosModule) => {
    const {
        content,
        empty,
        number,
        size,
        totalElements,
        totalPages,
    } = data;

    let list = [];

    if (isEventGuestPhotosModule) list = content[0]?.photos || [];
    else if (empty) list = [];
    else if (!empty && content) list = content;
    else list = data;

    return {
        data: list,
        ...((!empty && content) || empty) && {
            pagination: {
                count: totalElements,
                limit: size,
                page: number,
                totalPages,
            },
        },
        noData: list.length === 0,
    };
};

export const renderFormController = (control, data, renderFormControllerChildren) => data.map(({
    col,
    label,
    name,
    requestName,
    type,
}) => (
    <Grid
        {...col}
        key={name}
        item
    >
        <Controller
            control={control}
            key={name}
            name={name}
            render={({ field }) => renderFormControllerChildren(
                field,
                label,
                name,
                type,
                requestName,
            )}
        />
    </Grid>
));

export const debounce = (callback, time) => {
    let timeout = null;

    return (value, ...rest) => {
        if (timeout) clearTimeout(timeout);

        timeout = setTimeout(
            () => callback(
                value,
                ...rest,
            ),
            time,
        );
    };
};
