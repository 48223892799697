const filtersConstants = {
    all: "all",
    archived: "archived",
    email: "email",
    endDate: "endDate",
    phone: "phone",
    search: "search",
    startDate: "startDate",
    unarchived: "unarchived",
};

export default filtersConstants;
