/* eslint max-lines: 0 */
import {
    actionsConstants,
    btnsNamesConstants,
    filtersConstants,
    globalConstants,
    inputsLabelsConstants,
    messagesConstants,
    modulesConstants,
} from "@constants";
import CheckIcon from "@mui/icons-material/Check";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ClearIcon from "@mui/icons-material/Clear";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import LaunchIcon from "@mui/icons-material/Launch";
import LinkIcon from "@mui/icons-material/Link";
import {
    Autocomplete,
    Box,
    Button,
    CardContent,
    Checkbox,
    Grid,
    Link,
    MenuItem,
    TextField,
    Typography,
} from "@mui/material";
import {
    ButtonMenu,
    Calendar,
    CopyLinkBox,
    Loader,
    Modal,
    Pagination,
} from "atoms";
import { Header, NoData, Photos } from "components";
import {
    actionsData,
    directionsData,
    photosFiltersArea,
    regexData,
    routesUrlsData,
    sortsData,
} from "data";
import dayjs from "dayjs";
import {
    useGenerateEventZipFolderData,
    useGetEventData,
    useGetEventGuestsListData,
    useShareEventGalleryData,
} from "hooks";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import {
    generateEventZipFolder,
    getEvent,
    getEventGuests,
    openAlert,
    showLoadingLoader,
} from "redux/actions";
import * as types from "redux/types";
import { globalStyles, StyledCard } from "styles";
import { baseTheme, lightTheme } from "theme";
import {
    getConvertedListData,
    getEventPhotosQuery,
    getQuery,
    getSearchParams,
} from "utils";
import { ServerErrorView } from "views";

function PhotosLayout({
    backRouteUrl,
    data,
    directionArray,
    error,
    getListAction,
    headerTitle,
    list,
    moduleName,
    pageTitle,
    pagination,
    photosLoader,
    sortArray,
    withoutFilters,
    withoutPagination,
}) {
    const dispatch = useDispatch();

    const theme = useSelector((state) => state.themeReducer.theme);

    const eventGuestsStoredData = useSelector((state) => state.eventsReducer.guests);

    const details = useSelector((state) => state.eventsReducer.details);

    const generated = useSelector((state) => state.eventsReducer.generated);

    const loggingOut = useSelector((state) => state.authReducer.loggingOut);

    const loading = useSelector((state) => state.loadingReducer.show);

    const [filters, setFilters] = useState({
        endDate: null,
        search: "",
        startDate: null,
    });

    const [filtering, setFiltering] = useState(false);

    const [modalData, setModalData] = useState({
        btnColor: null,
        btnName: null,
        content: null,
        id: null,
        secondBtnActionHandler: () => {},
        secondBtnColor: null,
        secondBtnName: null,
        show: false,
        submitModalActionHandler: () => {},
        title: null,
        withSecondBtn: true,
    });

    const [refetchEventData, setRefetchEventData] = useState(false);

    const formMethods = useForm({
        defaultValues: { email: "" },
    });

    const navigate = useNavigate();

    const location = useLocation();

    const {
        eventId: eventIdParam,
        id: idParam,
    } = useParams();

    const [searchParams] = useSearchParams();

    const {
        data: eventGuestsListData,
        error: eventGuestsListError,
        isError: eventGuestsListIsError,
        isLoading: eventGuestsListIsLoading,
        isSuccess: eventGuestsListIsSuccess,
        mutate: getEventGuestsListMutate,
    } = useGetEventGuestsListData();

    const {
        data: getEventData,
        error: getEventError,
        isError: getEventIsError,
        isFetching: getEventIsFetching,
        isLoading: getEventIsLoading,
        isRefetchError: getEventIsRefetchError,
        isSuccess: getEventIsSuccess,
        refetch: refetchGetEventData,
    } = useGetEventData(idParam || eventIdParam);

    const {
        error: generateEventZipFolderError,
        isError: generateEventZipFolderIsError,
        isLoading: generateEventZipFolderIsLoading,
        isSuccess: generateEventZipFolderIsSuccess,
        mutate: generateEventZipFolderMutate,
    } = useGenerateEventZipFolderData();

    const {
        isLoading: shareEventGalleryIsLoading,
        mutate: shareEventGalleryMutate,
    } = useShareEventGalleryData();

    const { inProgress: inProgressConstant } = globalConstants;

    const {
        clearFilters: clearFiltersBtnName,
        copySlideshowLink: copySlideshowLinkBtnName,
        filter: filterBtnName,
        galleryActions: galleryActionsBtnName,
        generateZipFolder: generateZipFolderBtnName,
        openSlideshowPreview: openSlideshowPreviewBtnName,
        shareSelected: shareSelectedBtnName,
        shareToAll: shareToAllBtnName,
    } = btnsNamesConstants;

    const {
        fail: failMsg,
        generateEventZipFolderMsgs: {
            alert: generateEventZipFolderAlertMsg,
            content: {
                downloadLastGeneratedZipFolder: downloadLastGeneratedZipFolderContentMsg,
                folderCreationTime: folderCreationTimeContentMsg,
                formTitle: formTitleContentMsg,
                lastGenerationTime: lastGenerationTimeContentMsg,
                regenerate: regenerateContentMsg,
            },
            openModel: openGenerateEventZipFolderMsg,
            pending: generateEventZipFolderPendingMsg,
            validation: generateEventZipFolderValidationFailMsg,
        },
        shareEventGalleryMsgs: {
            alert: shareEventGalleryAlertMsg,
            pending: shareEventGalleryPendingMsg,
        },
        success: successMsg,
    } = messagesConstants;

    const { email: emailInputLabel } = inputsLabelsConstants;

    const {
        eventGuestPhotos: eventGuestPhotosModule,
        eventPhotos: eventPhotosModule,
        eventSlideshow: eventSlideshowModule,
    } = modulesConstants;

    const {
        eventSlideshow: { action: slideshowAction },
        shareGallery: { key: shareGalleryKey },
    } = actionsConstants;

    const { all: allFilter } = filtersConstants;

    const { selectGuests: selectGuestsInputLabel } = inputsLabelsConstants;

    const { gallery: galleryActionsData } = actionsData.eventPhotos;

    const { email: emailRegex } = regexData;

    const { eventGuests: eventGuestsDirection } = directionsData;

    const { eventGuests: eventGuestsSort } = sortsData;

    const {
        slideshow: eventSlideshowRouteUrl,
        url: eventsRouteUrl,
    } = routesUrlsData.events;

    const {
        page: paginationPage,
        totalPages: paginationTotalPages,
    } = pagination;

    const {
        endDate,
        search,
        startDate,
    } = filters;

    const { eventFile } = details;

    const { data: eventGuestsListStoredData } = eventGuestsStoredData;

    const {
        endDate: endDateSearchParam,
        limit: limitSearchParam,
        search: searchSearchParam,
        startDate: startDateSearchParam,
    } = getSearchParams(
        searchParams,
        window?.__RUNTIME_CONFIG__?.REACT_APP_LIST_LIMIT, // eslint-disable-line
    );

    const {
        control,
        formState: { errors },
        getValues,
    } = formMethods;

    const { linkColor } = globalStyles();

    const generateEventZipFolderHandler = () => {
        const emailValue = getValues().email;

        const submitModalDataHandler = () => {
            setModalData({
                ...modalData,
                show: false,
            });

            toast.loading(generateEventZipFolderPendingMsg);

            generateEventZipFolderMutate({
                id: idParam,
                query: emailValue ? `?email=${emailValue}` : "",
                subApi: "create",
            });
        };

        if (emailValue) {
            if (emailValue.match(emailRegex)) submitModalDataHandler();
            else {
                dispatch(openAlert(
                    generateEventZipFolderValidationFailMsg,
                    failMsg,
                ));
            }
        } else {
            dispatch(openAlert(
                generateEventZipFolderValidationFailMsg,
                failMsg,
            ));
        }
    };

    const renderGenerateZipFolderModal = () => {
        setModalData({
            content: (
                <Box>
                    <Box
                        alignItems="center"
                        display="flex"
                    >
                        <Typography
                            alignItems="center"
                            color={theme === "dark" ? "white" : "GrayText"}
                            display="flex"
                            fontSize={12}
                            mr={1}
                            variant="caption"
                        >
                            <CheckIcon />
                            {folderCreationTimeContentMsg}
                        </Typography>
                        <time
                            style={{
                                color: theme === "dark" ? "white" : "GrayText",
                                fontSize: 12,
                                marginBottom: 24,
                            }}
                        >
                            {dayjs(eventFile?.creationTimeStamp).format("MM/DD/YYYY HH:mm")}
                        </time>
                    </Box>
                    <Box
                        alignItems="center"
                        display="flex"
                        mt={-2}
                    >
                        <Typography
                            alignItems="center"
                            color={baseTheme.palette.success.dark}
                            display="flex"
                            fontSize={12}
                            mr={1}
                            variant="caption"
                        >
                            <DoneAllIcon />
                            {lastGenerationTimeContentMsg}
                        </Typography>
                        <time
                            style={{
                                color: baseTheme.palette.success.dark,
                                fontSize: 12,
                                marginBottom: 24,
                            }}
                        >
                            {dayjs(eventFile?.lastUpdateTimeStamp).format("MM/DD/YYYY HH:mm")}
                        </time>
                    </Box>
                    <FormProvider {...formMethods}>
                        <form
                            style={{
                                background: baseTheme.palette.grey[theme === "dark" ? 800 : 300],
                                borderRadius: 10,
                                marginTop: 5,
                                paddingBlock: 0,
                                paddingInline: 20,
                                paddingTop: 10,
                            }}
                        >
                            <Grid
                                mt={2}
                                container
                            >
                                <Typography
                                    color={theme === "dark" ? "white" : "GrayText"}
                                    variant="body2"
                                >
                                    {formTitleContentMsg}
                                </Typography>
                                <Grid
                                    alignItems="center"
                                    display="flex"
                                    flexWrap="wrap"
                                    gap={1}
                                    justifyContent="space-between"
                                    xs={12}
                                    item
                                >
                                    <Box
                                        width={{
                                            md: "50%",
                                            xs: "100%",
                                        }}
                                    >
                                        <Controller
                                            control={control}
                                            name="email"
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    error={errors.email}
                                                    helperText={errors.email && errors.email?.message}
                                                    label={emailInputLabel}
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </Box>
                                    <Box>
                                        <Button
                                            size="large"
                                            variant="contained"
                                            onClick={generateEventZipFolderHandler}
                                        >
                                            {generateZipFolderBtnName}
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid
                                    xs={12}
                                    item
                                >
                                    {eventFile?.fileUrl ? (
                                        <>
                                            <Typography
                                                color={theme === "dark" ? "white" : "GrayText"}
                                                mt={2}
                                                variant="body2"
                                            >
                                                {downloadLastGeneratedZipFolderContentMsg}
                                            </Typography>
                                            <Box
                                                alignItems="center"
                                                border="1px solid"
                                                borderColor={lightTheme.palette.primary.main}
                                                borderRadius={2}
                                                display="flex"
                                                justifyContent="space-between"
                                                mb={2}
                                                mt={-2}
                                                paddingBlock={0}
                                                paddingInline={2}
                                            >
                                                <Box
                                                    display="flex"
                                                    gap={1}
                                                    justifyContent="center"
                                                    mt={2}
                                                >
                                                    <LinkIcon sx={{ color: theme === "dark" ? "white" : "GrayText" }} />
                                                    <Link
                                                        className={[linkColor, "whiteGrey"].join(" ")}
                                                        href={eventFile?.fileUrl}
                                                        sx={{ textDecorationColor: baseTheme.palette.grey[600] }}
                                                    >
                                                        {`${eventFile?.fileUrl?.slice(
                                                            0,
                                                            10,
                                                        )}...`}
                                                    </Link>
                                                </Box>
                                                <Box>
                                                    <Link href={eventFile?.fileUrl}><LaunchIcon /></Link>
                                                </Box>
                                            </Box>
                                        </>
                                    ) : (
                                        <Box
                                            alignItems="center"
                                            display="flex"
                                            gap={1}
                                            mt={2}
                                        >
                                            <Typography
                                                color={theme === "dark" ? "white" : "GrayText"}
                                                variant="body2"
                                                width={{
                                                    md: "fit-content",
                                                    xs: "60%",
                                                }}
                                            >
                                                {downloadLastGeneratedZipFolderContentMsg}
                                                :
                                            </Typography>
                                            <Typography
                                                color={baseTheme.palette.success.main}
                                                fontSize={12}
                                                variant="caption"
                                            >
                                                {eventFile?.status || inProgressConstant}
                                                ...
                                            </Typography>
                                        </Box>
                                    )}
                                    {eventFile?.hasUpdate && (
                                        <Typography
                                            color={theme === "dark" ? "white" : "GrayText"}
                                            variant="body2"
                                        >
                                            {regenerateContentMsg}
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                        </form>
                    </FormProvider>
                </Box>
            ),
            id: idParam,
            show: true,
            title: generateEventZipFolderAlertMsg,
            withSecondBtn: false,
        });
    };

    const clickGalleryActionHandler = (action) => {
        let selectedEventGuests = [];

        if (action === shareGalleryKey) {
            setModalData({
                btnColor: "primary",
                btnName: shareSelectedBtnName,
                content: (
                    <Autocomplete
                        getOptionLabel={(option) => option?.phoneNumber || option?.email}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        options={eventGuestsListStoredData}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                disabled={eventGuestsListIsLoading || eventGuestsListIsError}
                                label={selectGuestsInputLabel}
                                placeholder={selectGuestsInputLabel}
                                variant="outlined"
                            />
                        )}
                        renderOption={(props, option, { selected }) => (
                            <Box
                                sx={{ display: "block" }}
                                {...props}
                            >
                                <Checkbox
                                    checked={selected}
                                    checkedIcon={<CheckBoxIcon color="primary" />}
                                    icon={<CheckBoxOutlineBlankIcon />}
                                    style={{ marginRight: 8 }}
                                />
                                {option?.phoneNumber}
                                {option?.email}
                            </Box>
                        )}
                        disableCloseOnSelect
                        multiple
                        onChange={(_, value) => selectedEventGuests = value}
                    />
                ),
                id: idParam,
                secondBtnActionHandler: () => {
                    setModalData({
                        ...modalData,
                        show: false,
                    });

                    toast.loading(shareEventGalleryPendingMsg);

                    shareEventGalleryMutate({
                        data: { guests: [] },
                        id: idParam,
                        query: "?all=true",
                    });
                },
                secondBtnColor: "success",
                secondBtnName: shareToAllBtnName,
                show: true,
                submitModalActionHandler: () => {
                    setModalData({
                        ...modalData,
                        show: false,
                    });

                    toast.loading(shareEventGalleryPendingMsg);

                    shareEventGalleryMutate({
                        data: { guests: selectedEventGuests.map((guest) => guest?.id) },
                        id: idParam,
                        query: "?all=false",
                    });
                },
                title: shareEventGalleryAlertMsg,
                withSecondBtn: true,
            });
        } else if (eventFile) {
            toast.loading(openGenerateEventZipFolderMsg);

            refetchGetEventData().then(() => {
                toast.dismiss();

                renderGenerateZipFolderModal();
            });
        } else {
            toast.loading(generateEventZipFolderPendingMsg);

            generateEventZipFolderMutate({
                id: idParam,
                subApi: "create",
            });
        }
    };

    const clickFilterBtnHandler = () => {
        getListAction({
            data: {
                count: window?.__RUNTIME_CONFIG__?.REACT_APP_LIST_LIMIT, // eslint-disable-line
                direction: directionArray,
                page: 0,
                search,
                sort: sortArray,
            },
            id: idParam,
            query: getEventPhotosQuery(
                startDate ? new Date(startDate).toISOString() : null,
                endDate ? new Date(endDate).toISOString() : null,
            ),
        });

        const query = getQuery(
            0,
            window?.__RUNTIME_CONFIG__?.REACT_APP_LIST_LIMIT, // eslint-disable-line
            null,
            search,
            startDate ? new Date(startDate).toISOString() : null,
            endDate ? new Date(endDate).toISOString() : null,
        );

        navigate({ search: query });

        setFiltering(true);
    };

    const clickClearFiltersBtnHandler = () => {
        setFilters({
            endDate: null,
            search: "",
            startDate: null,
        });

        getListAction({
            data: {
                count: window?.__RUNTIME_CONFIG__?.REACT_APP_LIST_LIMIT, // eslint-disable-line
                direction: directionArray,
                page: 0,
                search: "",
                sort: sortArray,
            },
            id: idParam,
            query: "",
        });

        const query = getQuery(
            0,
            window?.__RUNTIME_CONFIG__?.REACT_APP_LIST_LIMIT, // eslint-disable-line
        );

        navigate({ search: query });

        setFiltering(false);
    };

    const changePageHandler = (_, page) => {
        getListAction({
            data: {
                count: limitSearchParam,
                direction: directionArray,
                page: parseInt(page - 1),
                search: searchSearchParam,
                sort: sortArray,
            },
            id: idParam,
            query: getEventPhotosQuery(
                startDateSearchParam,
                endDateSearchParam,
            ),
        });

        const query = getQuery(
            parseInt(page - 1),
            limitSearchParam,
            null,
            searchSearchParam,
            startDateSearchParam,
            endDateSearchParam,
        );

        navigate({ search: query });

        window.scrollTo({ // eslint-disable-line
            behavior: "smooth",
            top: 0,
        });
    };

    const renderList = () => {
        if (photosLoader || loading) return <Loader />;

        if (list?.length === 0 && data?.noData) return <NoData />;

        return (
            <>
                <Photos
                    list={list}
                    moduleName={moduleName}
                />
                {!withoutPagination && (
                    <Pagination
                        count={parseInt(paginationTotalPages)}
                        handleChange={changePageHandler}
                        page={paginationPage + 1}
                    />
                )}
            </>
        );
    };

    const renderFiltersAndActionsArea = () => {
        if (
            list?.length === 0
            && !search
            && !startDate
            && !endDate
            && !filtering
        ) return null;

        if (moduleName === eventPhotosModule) {
            return (
                <StyledCard mb={2}>
                    <CardContent>
                        <Box
                            display="flex"
                            gap={2}
                            mb={2}
                        >
                            <ButtonMenu btnName={galleryActionsBtnName}>
                                {galleryActionsData.map(({
                                    Icon,
                                    action,
                                    key,
                                }) => (
                                    <MenuItem
                                        component={Button}
                                        key={key}
                                        style={{ width: "100%" }}
                                        disableRipple
                                        onClick={() => clickGalleryActionHandler(key)}
                                    >
                                        <Icon style={{ marginRight: "5px" }} />
                                        {action}
                                    </MenuItem>
                                ))}
                            </ButtonMenu>
                            <Button
                                style={{ textDecoration: "none" }}
                                variant="contained"
                                onClick={() => navigate(
                                    `${eventsRouteUrl}/${idParam}${eventSlideshowRouteUrl}`,
                                    { state: { from: location.pathname + location.search } },
                                )}
                            >
                                {slideshowAction}
                            </Button>
                        </Box>
                        <Box
                            display="flex"
                            flexWrap="wrap"
                            justifyContent="space-between"
                        >
                            <Grid
                                alignItems="center"
                                justifyContent="space-between"
                                spacing={2}
                                container
                            >
                                {photosFiltersArea.map(({
                                    col,
                                    label,
                                    name,
                                    type,
                                }) => (
                                    <Grid
                                        {...col}
                                        key={name}
                                        item
                                    >
                                        {type === "date" ? (
                                            <Calendar
                                                label={label}
                                                value={filters[name]}
                                                onChange={(date) => {
                                                    setFilters({
                                                        ...filters,
                                                        [name]: date,
                                                    });
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") clickFilterBtnHandler();
                                                }}
                                            />
                                        ) : (
                                            <TextField
                                                label={label}
                                                value={filters[name]}
                                                variant="outlined"
                                                fullWidth
                                                onChange={(e) => {
                                                    setFilters({
                                                        ...filters,
                                                        [name]: e.target.value,
                                                    });
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") clickFilterBtnHandler();
                                                }}
                                            />
                                        )}
                                    </Grid>
                                ))}
                                <Grid
                                    display="flex"
                                    justifyContent="space-between"
                                    lg={3}
                                    item
                                >
                                    <Button
                                        disabled={!search && !startDate && !endDate && !filtering}
                                        size="small"
                                        style={{ marginRight: "5px" }}
                                        variant="contained"
                                        onClick={clickFilterBtnHandler}
                                    >
                                        <FilterAltIcon style={{ marginRight: "5px" }} />
                                        {filterBtnName}
                                    </Button>
                                    {(search || startDate || endDate) && filtering && (
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            onClick={clickClearFiltersBtnHandler}
                                        >
                                            <ClearIcon style={{ marginRight: "5px" }} />
                                            {clearFiltersBtnName}
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </StyledCard>
            );
        }
    };

    useEffect(() => {
        dispatch(showLoadingLoader());
    }, []); // eslint-disable-line

    useEffect(() => {
        if (moduleName === eventPhotosModule) {
            setFilters({
                endDate: endDateSearchParam,
                search: searchSearchParam,
                startDate: startDateSearchParam,
            });

            if (endDateSearchParam || searchSearchParam || startDateSearchParam) setFiltering(true);
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        if (moduleName === eventPhotosModule) {
            getEventGuestsListMutate({
                data: {
                    direction: eventGuestsDirection,
                    sort: eventGuestsSort,
                },
                id: idParam,
                subApi: allFilter,
            });
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        if (moduleName === eventPhotosModule || moduleName === eventSlideshowModule || moduleName === eventGuestPhotosModule) refetchGetEventData();
    }, [moduleName]); // eslint-disable-line

    useEffect(
        () => {
            if (
                eventGuestsListIsLoading
                || getEventIsLoading
                || getEventIsFetching
                || generateEventZipFolderIsLoading
                || shareEventGalleryIsLoading
            ) dispatch({ type: types.EVENTS_REQUEST });
        },
        [
            dispatch,
            eventGuestsListIsLoading,
            getEventIsLoading,
            getEventIsFetching,
            generateEventZipFolderIsLoading,
            shareEventGalleryIsLoading,
        ],
    );

    useEffect(() => {
        if (eventGuestsListIsSuccess && list.length > 0) {
            dispatch(getEventGuests(
                getConvertedListData(eventGuestsListData.data),
                successMsg,
            ));
        }
    }, [eventGuestsListIsSuccess, list]); // eslint-disable-line

    useEffect(() => {
        if ((getEventIsSuccess && list.length > 0) || refetchEventData) {
            dispatch(getEvent(
                getEventData.data,
                successMsg,
            ));
        }
    }, [getEventIsSuccess, refetchEventData, list]); // eslint-disable-line

    useEffect(() => {
        if (generateEventZipFolderIsSuccess) {
            dispatch(generateEventZipFolder(successMsg));

            toast.dismiss();
        }
    }, [generateEventZipFolderIsSuccess]); // eslint-disable-line

    useEffect(() => {
        if (eventGuestsListIsError && list.length > 0) {
            dispatch(getEventGuests(
                null,
                failMsg,
                eventGuestsListError,
            ));
        }
    }, [eventGuestsListIsError, list]); // eslint-disable-line

    useEffect(() => {
        if ((getEventIsError && list.length > 0) || getEventIsRefetchError) {
            dispatch(getEvent(
                null,
                failMsg,
                getEventError,
            ));
        }
    }, [getEventIsError, getEventIsRefetchError, list]); // eslint-disable-line

    useEffect(() => {
        if (generateEventZipFolderIsError) {
            dispatch(generateEventZipFolder(
                failMsg,
                generateEventZipFolderError,
            ));

            toast.dismiss();
        }
    }, [generateEventZipFolderIsError]); // eslint-disable-line

    useEffect(() => {
        if (generated) refetchGetEventData().then(() => setRefetchEventData(true));
    }, [generated]); // eslint-disable-line

    useEffect(() => {
        if (refetchEventData) {
            renderGenerateZipFolderModal();

            setRefetchEventData(false);
        }
    }, [eventFile, refetchEventData]); // eslint-disable-line

    if (error?.status === 500) return <ServerErrorView />;

    return loggingOut ? (
        <>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <Grid
                alignItems="center"
                minHeight="100vh"
                spacing={2}
                container
            >
                <Loader />
            </Grid>
        </>

    ) : (
        <>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <Header
                backRouteUrl={backRouteUrl}
                hasBackBtn={!!backRouteUrl}
                title={details?.title && getEventIsSuccess ? `${details.title} ${headerTitle}` : headerTitle}
            />
            {moduleName === eventSlideshowModule && list.length > 0 && !loading && (
                <CopyLinkBox
                    buttonLinkText={openSlideshowPreviewBtnName}
                    copyBtnName={copySlideshowLinkBtnName}
                    link={window?.__RUNTIME_CONFIG__?.REACT_APP_PUBLIC_PAGE_URL + `event/slideshow?event=${idParam}`} // eslint-disable-line
                    isButtonLink
                />
            )}
            {!withoutFilters && renderFiltersAndActionsArea()}
            <StyledCard>
                <CardContent>{renderList()}</CardContent>
            </StyledCard>
            <Modal
                btnColor={modalData.btnColor}
                btnName={modalData.btnName}
                content={modalData.content}
                secondBtnColor={modalData.secondBtnColor}
                secondBtnName={modalData.secondBtnName}
                showModal={modalData.show}
                title={modalData.title}
                withSecondBtn={modalData.withSecondBtn}
                onClickSecondBtnAction={modalData.secondBtnActionHandler}
                onSubmitModalAction={modalData.submitModalActionHandler}
                onCloseModal={() => {
                    setModalData({
                        ...modalData,
                        show: false,
                    });
                }}
            />
        </>
    );
}

export default PhotosLayout;
