import { btnsNamesConstants, globalConstants } from "@constants";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import {
    Box,
    Button,
    ImageListItem,
    ImageListItemBar,
} from "@mui/material";
import { PhotoActionsMenu } from "atoms";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { downloadEventPhoto } from "redux/actions";
import { baseTheme as theme } from "theme";

function Photo({
    data,
    index,
    lightboxImages,
    modalData,
    moduleName,
    setLightboxData,
    setModalData,
}) {
    const dispatch = useDispatch();

    const { id: idParam } = useParams();

    const { by: byConstant } = globalConstants;

    const { download: downloadBtnName } = btnsNamesConstants;

    const {
        dropboxOverlayImage,
        id,
        isSlideShow,
        mainImage,
        overlayImage,
        photographer,
        thumbnailImage,
        timeStamp,
    } = data;

    return (
        <ImageListItem
            className="image-list-item"
            key={id}
        >
            <Button
                onClick={() => setLightboxData({
                    images: lightboxImages,
                    open: true,
                    startIndex: index,
                })}
            >
                {isSlideShow && (
                    <Box
                        sx={{
                            left: 10,
                            position: "absolute",
                            top: 10,
                        }}
                    >
                        <SlideshowIcon style={{ color: theme.palette.grey[100] }} />
                    </Box>
                )}
                <img
                    alt="thumbnail_image"
                    loading="lazy"
                    src={thumbnailImage}
                    srcSet={thumbnailImage}
                    style={{
                        pointerEvents: "none",
                        width: "100%",
                    }}
                    onContextMenu={(e) => e.preventDefault()}
                    onTouchStart={(e) => e.preventDefault()}
                />
            </Button>
            <ImageListItemBar
                subtitle={dayjs(timeStamp).format("MM/DD/YYYY HH:mm")}
                title={`${byConstant}${photographer.firstName}${" "}${photographer.lastName}`}
                actionIcon={(
                    <Box display="flex">
                        <Button
                            size="small"
                            variant="contained"
                            onClick={() => dispatch(downloadEventPhoto(overlayImage))}
                        >
                            {downloadBtnName}
                        </Button>
                        <PhotoActionsMenu
                            dropboxOverlayImage={dropboxOverlayImage}
                            eventId={idParam}
                            mainImage={mainImage}
                            modalData={modalData}
                            moduleName={moduleName}
                            overlayImage={overlayImage}
                            photoId={id}
                            setModalData={setModalData}
                            slideshowStatus={isSlideShow}
                        />
                    </Box>
                )}
            />
        </ImageListItem>
    );
}

export default Photo;
