export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";

export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS";

export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS";

export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";

export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";

export const ARCHIVE_EVENT_SUCCESS = "ARCHIVE_EVENT_SUCCESS";

export const UNARCHIVE_EVENT_SUCCESS = "UNARCHIVE_EVENT_SUCCESS";

export const ASSIGN_EVENT_SUCCESS = "ASSIGN_EVENT_SUCCESS";

export const UPLOAD_EVENT_OVERLAY_SUCCESS = "UPLOAD_EVENT_OVERLAY_SUCCESS";

export const SET_DEFAULT_EVENT_OVERLAY_SUCCESS = "SET_DEFAULT_EVENT_OVERLAY_SUCCESS";

export const DELETE_EVENT_OVERLAY_SUCCESS = "DELETE_EVENT_OVERLAY_SUCCESS";

export const ADD_EVENT_DOMAIN_SUCCESS = "ADD_EVENT_DOMAIN_SUCCESS";

export const DELETE_EVENT_DOMAIN_SUCCESS = "DELETE_EVENT_DOMAIN_SUCCESS";

export const ADD_EVENT_PAGE_ACTION_SUCCESS = "ADD_EVENT_PAGE_ACTION_SUCCESS";

export const DELETE_EVENT_PAGE_ACTION_SUCCESS = "DELETE_EVENT_PAGE_ACTION_SUCCESS";

export const UPLOAD_EVENT_BANNER_IMAGE_SUCCESS = "UPLOAD_EVENT_BANNER_IMAGE_SUCCESS";

export const CHANGE_EVENT_UPDATE_FORM_FIELD = "CHANGE_EVENT_UPDATE_FORM_FIELD";

export const CHANGE_EVENT_UPDATE_FORM_VALIDATION = "CHANGE_EVENT_UPDATE_FORM_VALIDATION";

export const GET_EVENT_GUESTS_SUCCESS = "GET_EVENT_GUESTS_SUCCESS";

export const EXPORT_EVENT_GUESTS_SUCCESS = "EXPORT_EVENT_GUESTS_SUCCESS";

export const GET_EVENT_PHOTOS_SUCCESS = "GET_EVENT_PHOTOS_SUCCESS";

export const RESEND_EVENT_PHOTO_SUCCESS = "RESEND_EVENT_PHOTO_SUCCESS";

export const DELETE_EVENT_PHOTO_SUCCESS = "DELETE_EVENT_PHOTO_SUCCESS";

export const SHARE_EVENT_GALLERY_SUCCESS = "SHARE_EVENT_GALLERY_SUCCESS";

export const GENERATE_EVENT_ARCHIVE_FILE_SUCCESS = "GENERATE_EVENT_ARCHIVE_FILE_SUCCESS";

export const GET_EVENT_GUEST_PHOTOS_SUCCESS = "GET_EVENT_GUEST_PHOTOS_SUCCESS";

export const GET_EVENT_SLIDESHOW_SUCCESS = "GET_EVENT_SLIDESHOW_SUCCESS";

export const ADD_EVENT_PHOTO_TO_SLIDESHOW_SUCCESS = "ADD_EVENT_PHOTO_TO_SLIDESHOW_SUCCESS";

export const REMOVE_EVENT_PHOTO_FROM_SLIDESHOW_SUCCESS = "REMOVE_EVENT_PHOTO_FROM_SLIDESHOW_SUCCESS";

export const GET_EVENT_TEMPLATES_SUCCESS = "GET_EVENT_TEMPLATES_SUCCESS";

export const GET_EVENT_TEMPLATE_SUCCESS = "GET_EVENT_TEMPLATE_SUCCESS";

export const CREATE_EVENT_TEMPLATE_SUCCESS = "CREATE_EVENT_TEMPLATE_SUCCESS";

export const UPDATE_EVENT_TEMPLATE_SUCCESS = "UPDATE_EVENT_TEMPLATE_SUCCESS";

export const DELETE_EVENT_TEMPLATE_SUCCESS = "DELETE_EVENT_TEMPLATE_SUCCESS";

export const EVENTS_REQUEST = "EVENTS_REQUEST";

export const EVENTS_FAIL = "EVENTS_FAIL";
