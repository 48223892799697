import { messagesConstants } from "@constants";
import {
    Box,
    Button,
    Link,
    Typography,
} from "@mui/material";
import { useState } from "react";
// import { useNavigate } from "react-router-dom";
import { globalStyles } from "styles";
import { baseTheme } from "theme";

function CopyLinkBox({
    buttonLinkText,
    copyBtnName,
    isButtonLink,
    link,
    linkText,
}) {
    const [copyLink, setCopyLink] = useState(false);

    const { linkColor } = globalStyles();

    // const navigate = useNavigate();

    const { copied: copiedMsg } = messagesConstants;

    return (
        <Box
            alignItems="center"
            display="flex"
            flexWrap="wrap"
            gap={2}
            justifyContent="flex-start"
            mb={2}
        >
            <Box>
                {isButtonLink ? (
                    <Button
                        style={{ textDecoration: "none" }}
                        variant="contained"
                        onClick={() => window.open(link, '_blank')} // eslint-disable-line
                    >
                        {buttonLinkText}
                    </Button>
                ) : (
                    <Link
                        className={[linkColor, "whiteGrey"].join(" ")}
                        href={link}
                        sx={{ textDecorationColor: baseTheme.palette.grey[600] }}
                        target="_blank"
                    >
                        {linkText}
                    </Link>
                )}
            </Box>
            <Box>
                <Button
                    variant="contained"
                    onClick={() => {
                        setCopyLink(true);

                        navigator.clipboard.writeText(link) // eslint-disable-line
                    }}
                >
                    {copyBtnName}
                </Button>
            </Box>
            {copyLink && (
                <Typography
                    color="primary"
                    variant="caption"
                >
                    {copiedMsg}
                </Typography>
            )}
        </Box>
    );
}

export default CopyLinkBox;
