import { apiClient, apisUrls } from "apis";

const {
    clone: cloneApiUrl,
    deletePhoto: deletePhotoApiUrl,
    events: {
        assign: assignEventApiUrl,
        create: createEventApiUrl,
        createCopyEvent: createCopyEventApiUrl,
        createUseTemplate: createUseTemplateApiUrl,
        delete: deleteEventApiUrl,
        deleteEventDomain: deleteEventDomainApiUrl,
        deleteEventOverlay: deleteEventOverlayApiUrl,
        deleteEventPageAction: deleteEventPageActionApiUrl,
        eventSettings: eventSettingsApiUrl,
        export: exportGuestsListApiUrl,
        generateEventZipFolder: generateEventZipFolderApiUrl,
        getOne: getOneEventApiUrl,
        guestPhotos: eventGuestPhotosListApiUrl,
        guests: eventGuestsListApiUrl,
        photos: eventPhotosListApiUrl,
        publicPage: publicPageApiUrl,
        resendEventPhoto: resendEventPhotoApiUrl,
        setDefaultEventOverlay: setDefaultEventOverlayApiUrl,
        shareEventGallery: shareEventGalleryApiUrl,
        slideshow: eventSlideshowListApiUrl,
        templates: eventTemplatesListApiUrl,
        toggleArchive: toggleArchiveEventApiUrl,
        toggleEventSlideshowPhoto: toggleEventSlideshowPhotoApiUrl,
        update: updateEventApiUrl,
        uploadEventOverlay: uploadEventOverlayApiUrl,
        url: eventsListApiUrl,
    },
    uploadPhoto: uploadPhotoApiUrl,
} = apisUrls;

/* Start Events & Event Details */
export const getEventsListData = (data) => apiClient.post(
    eventsListApiUrl,
    null,
    data,
);

export const getEventData = (id) => apiClient.get(
    getOneEventApiUrl,
    id,
);

export const createEventData = ({
    data,
    eventId,
    templateId,
}) => {
    let apiUrl = "";

    if (eventId) apiUrl = createCopyEventApiUrl;
    else if (templateId) apiUrl = createUseTemplateApiUrl;
    else apiUrl = createEventApiUrl;

    return apiClient.post(
        apiUrl,
        eventId || templateId,
        data,
        false,
        false,
        false,
        eventId || templateId ? cloneApiUrl : null,
    );
};

export const updateEventData = (data) => apiClient.update(
    updateEventApiUrl,
    null,
    data,
);

export const deleteEventData = (id) => apiClient["delete"](
    deleteEventApiUrl,
    id,
);

export const toggleArchiveEventData = (id) => apiClient.post(
    toggleArchiveEventApiUrl,
    id,
);

export const assignEventData = ({
    data,
    id,
}) => apiClient.post(
    assignEventApiUrl,
    id,
    data,
);

export const uploadEventOverlayData = ({
    data,
    id,
}) => apiClient.post(
    uploadEventOverlayApiUrl,
    id,
    data,
    true,
);

export const setDefaultEventOverlayData = ({
    id,
    subApi,
}) => apiClient.update(
    setDefaultEventOverlayApiUrl,
    id,
    null,
    false,
    null,
    subApi,
);

export const deleteEventOverlayData = (id) => apiClient["delete"](
    deleteEventOverlayApiUrl,
    id,
);

export const addEventDomainData = ({
    data,
    id,
    subApi,
}) => apiClient.post(
    eventSettingsApiUrl,
    id,
    data,
    false,
    false,
    false,
    subApi,
);

export const deleteEventDomainData = (id) => apiClient["delete"](
    deleteEventDomainApiUrl,
    id,
);

export const addEventPageActionData = ({
    data,
    id,
    subApi,
}) => apiClient.post(
    publicPageApiUrl,
    id,
    data,
    false,
    false,
    false,
    subApi,
);

export const deleteEventPageActionData = (id) => apiClient["delete"](
    deleteEventPageActionApiUrl,
    id,
);

export const uploadEventBannerImageData = (data) => apiClient.post(
    uploadPhotoApiUrl,
    null,
    data,
    true,
);
/* End Events & Event Details */

/* Start Event Guests */
export const getEventGuestsListData = ({
    data,
    id,
    subApi,
}) => apiClient.post(
    eventGuestsListApiUrl,
    id,
    data,
    false,
    false,
    false,
    subApi,
);

export const exportEventGuestsListData = (id, fileFilter) => apiClient.get(
    exportGuestsListApiUrl,
    id,
    null,
    true,
    fileFilter,
);
/* End Event Guests */

/* Start Event Photos */
export const getEventPhotosListData = ({
    data,
    id,
    query,
}) => apiClient.post(
    eventPhotosListApiUrl,
    id,
    data,
    false,
    false,
    false,
    null,
    query,
);

export const resendEventPhotoData = ({
    data,
    query,
}) => apiClient.post(
    resendEventPhotoApiUrl,
    null,
    data,
    false,
    false,
    false,
    null,
    query,
);

export const deleteEventPhotoData = (id) => apiClient["delete"](
    deletePhotoApiUrl,
    id,
);

export const shareEventGalleryData = ({
    data,
    id,
    query,
}) => apiClient.post(
    shareEventGalleryApiUrl,
    id,
    data,
    false,
    false,
    false,
    null,
    query,
);

export const generateEventZipFolderData = ({
    id,
    query,
    subApi,
}) => apiClient.post(
    generateEventZipFolderApiUrl,
    id,
    null,
    false,
    false,
    false,
    subApi,
    query,
);
/* End Event Photos */

/* Start Event Guest Photos */
export const getEventGuestPhotosListData = ({
    data,
    id,
}) => apiClient.post(
    eventGuestPhotosListApiUrl,
    id,
    data,
);
/* End Event Guest Photos */

/* Start Event Slideshow */
export const getEventSlideshowListData = (id) => apiClient.get(
    eventSlideshowListApiUrl,
    id,
);

export const toggleEventSlideshowPhotoData = (id) => apiClient.update(
    toggleEventSlideshowPhotoApiUrl,
    id,
    null,
    false,
    null,
    "toggle",
);
/* End Event Slideshow */

/* Start Event Templates */
export const getEventTemplatesListData = () => apiClient.get(eventTemplatesListApiUrl);

export const getEventTemplateData = (id) => apiClient.get(
    eventTemplatesListApiUrl,
    id,
);

export const createEventTemplateData = (data) => apiClient.post(
    eventTemplatesListApiUrl,
    null,
    data,
);

export const updateEventTemplateData = (data) => apiClient.update(
    eventTemplatesListApiUrl,
    null,
    data,
);

export const deleteEventTemplateData = (id) => apiClient["delete"](
    eventTemplatesListApiUrl,
    id,
);
/* End Event Templates */
