import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button } from "@mui/material";
import { useState } from "react";
import { StyledButtonMenu } from "styles";

function ButtonMenu({
    btnName,
    children,
}) {
    const [anchorEl, setAnchorEl] = useState(null);

    const openMenu = Boolean(anchorEl);

    return (
        <>
            <Button
                aria-haspopup="true"
                endIcon={<KeyboardArrowDownIcon />}
                variant="contained"
                disableElevation
                onClick={(event) => setAnchorEl(event.currentTarget)}
            >
                {btnName}
            </Button>
            <StyledButtonMenu
                anchorEl={anchorEl}
                elevation={0}
                open={openMenu}
                anchorOrigin={{
                    horizontal: "right",
                    vertical: "bottom",
                }}
                transformOrigin={{
                    horizontal: "right",
                    vertical: "top",
                }}
                onClose={() => setAnchorEl(null)}
            >
                {children}
            </StyledButtonMenu>
        </>
    );
}

export default ButtonMenu;
