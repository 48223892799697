const messagesConstants = {
    activateMsgs: {
        alert: "Are you sure you want to activate this user?",
        fail: "Failed to Activate",
        success: "Activated Successfully",
    },
    addEventDomainMsgs: {
        fail: "Failed to Add Event Domain",
        pending: "Adding Event Domain",
        success: "Added Event Domain Successfully",
    },
    addEventPageActionMsgs: {
        fail: "Failed to Add Page Action",
        pending: "Adding Page Action, Please wait...",
        success: "Added Page Action Successfully",
    },
    addToSlideshowMsgs: {
        alert: "Are you sure you want to add this photo to the Event Slideshow?",
        fail: "Failed to Add",
        success: "Added Successfully",
    },
    archiveMsgs: {
        alert: "Are you sure you want to archive this Event?",
        fail: "Failed to Archive",
        success: "Archived Successfully",
    },
    assignMsgs: {
        alert: "Assign Photographers to this Event",
        fail: "Failed to Assign",
        success: "Assigned Successfully",
    },
    authMsgs: {
        login: { fail: "Failed to Login, some information is incorrect" },
        noAccess: "This User has no access to the dashboard",
        setPassword: {
            fail: "Failed to Set Password",
            success: "Set Password Successfully",
        },
        unAuthorized: "Login Session is expired",
    },
    copied: "Copied..!",
    createMsgs: {
        fail: "Failed to Create",
        success: "Created Successfully",
    },
    deactivateMsgs: {
        alert: "Are you sure you want to deactivate this user?",
        fail: "Failed to Deactivate",
        success: "Deactivated Successfully",
    },
    deleteMsgs: {
        alert: "Are you sure you want to delete this ? It can't be restored after deleting",
        fail: "Failed to Delete",
        pending: "Deleting, Please wait...",
        success: "Deleted Successfully",
    },
    downloadMsgs: { fail: "Failed to Download" },
    exportMsgs: {
        fail: "Failed to Export",
        pending: "Exporting List Data",
        success: "Exported Successfully",
    },
    fail: "error",
    generateEventZipFolderMsgs: {
        alert: "Generate Event Zip Folder",
        content: {
            downloadLastGeneratedZipFolder: "Download Last Generated Zip Folder",
            folderCreationTime: "Created At: ",
            formTitle: "Enter your email to Generate zip folder & link",
            lastGenerationTime: "Completed At: ",
            regenerate: "* New Images were added to the event! Please regenerate the Folder",
        },
        fail: "Failed to Generate Event Zip Folder",
        openModel: "Opening Generate Event Zip Folder Details",
        pending: "Generating Event Zip Folder, Please wait...",
        success: "Generated Event Zip Folder Successfully",
        validation: "Please add valid Email",
    },
    inputsMsgs: {
        action: "Enter Action..!",
        date: "Enter Date..!",
        domain: "Enter Domain..!",
        email: "Enter Email..!",
        event: "Enter Event..!",
        firstName: "Enter First Name..!",
        invalidDomain: "Invalid Domain..!",
        invalidEmail: "Invalid Email..!",
        invalidName: "Invalid Name ..!",
        invalidPhoneNumber: "Invalid Phone Number ..!",
        invalidUrl: "Invalid Url..!",
        label: "Enter Label..!",
        lastName: "Enter Last Name..!",
        name: "Enter Name..!",
        notMatchingPassword: "Password is not matching..!",
        password: "Enter Password..!",
        phoneNumber: "Enter Phone Number..!",
        subject: "Enter Subject..!",
        template: "Enter Template..!",
        templateName: "Enter Template Name..!",
        text: "Enter Text..!",
        title: "Enter Title..!",
        url: "Enter Url..!",
    },
    removeFromSlideshowMsgs: {
        alert: "Are you sure you want to remove this photo from the Event Slideshow?",
        fail: "Failed to Remove",
        success: "Removed Successfully",
    },
    resendEventPhotoMsgs: {
        alert: "Resend Event Photo",
        fail: "Failed to Send Event Photo",
        pending: "Resending Event Photo, Please wait...",
        success: "Sended Event Photo Successfully",
        validation: "Please add valid Phone Number & Email",
    },
    resetPasswordMsgs: {
        alert: "Are you sure you want to send a reset password email to this user?",
        fail: "Failed to Reset Password",
        pending: "Sending Reset Password Email, Please wait...",
        success: "Sent Reset Password Email Successfully",
    },
    saveMsgs: {
        fail: "Failed to Save",
        success: "Saved Successfully",
    },
    shareEventGalleryMsgs: {
        alert: "Select Guests to share Event with",
        fail: "Failed to Share Event Gallery",
        pending: "Sharing Event Gallery, Please wait...",
        success: "Shared Event Gallery Successfully",
    },
    success: "success",
    unarchiveMsgs: {
        alert: "Are you sure you want to unarchived this Event?",
        fail: "Failed to Unarchive",
        success: "Unarchived Successfully",
    },
    uploadEventBannerImageMsgs: {
        fail: "Failed to Upload Event Banner Image",
        pending: "Uploading Event Banner Image, Please wait...",
        success: "Uploaded Event Banner Image Successfully",
    },
    uploadEventOverlayMsgs: {
        fail: "Failed to Upload Event Overlay",
        pending: "Uploading Event Overlay, Please wait...",
        success: "Uploaded Event Overlay Successfully",
    },
};

export default messagesConstants;
