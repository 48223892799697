import {
    btnsNamesConstants,
    inputsLabelsConstants,
    messagesConstants,
    modulesConstants,
    tooltipsConstants,
    viewsConstants,
} from "@constants";
import { yupResolver } from "@hookform/resolvers/yup";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Box,
    FormControl,
    FormControlLabel,
    IconButton,
    ImageListItem,
    ImageListItemBar,
    InputLabel,
    Link,
    MenuItem,
    Radio,
    Select,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { Calendar, CopyLinkBox } from "atoms";
import { aspectRatiosData, updateEventFormData } from "data";
import { useDeleteEventOverlayData, useUploadEventOverlayData } from "hooks";
import { FormLayout } from "layouts";
import { QRCodeCanvas } from "qrcode.react";
import { useCallback, useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteEventOverlay, uploadEventOverlay } from "redux/actions";
import * as types from "redux/types";
import { updateEventDetailsFormSchema } from "schemas";
import { StyledFileUploader } from "styles";
import { baseTheme as theme } from "theme";
import { debounce, getFormData, renderFormController } from "utils";

function UpdateEventDetailsForm({
    action,
    moduleName,
    onSubmitForm,
    values,
}) {
    const dispatch = useDispatch();

    const sending = useSelector((state) => state.sendingReducer.show);

    const lastUploadedEventOverlay = useSelector((state) => state.eventsReducer.lastUploadedEventOverlay);

    const uploaded = useSelector((state) => state.eventsReducer.uploaded);

    const deleted = useSelector((state) => state.eventsReducer.deleted);

    const [portraitOverlays, setPortraitOverlays] = useState([]);

    const [landscapeOverlays, setLandscapeOverlays] = useState([]);

    const [defaultPortraitOverlayId, setDefaultPortraitOverlayId] = useState(null);

    const [defaultLandscapeOverlayId, setDefaultLandscapeOverlayId] = useState(null);

    const [uploadEventOverlayTypeError, setUploadEventOverlayTypeError] = useState(false);

    const [copyEventLink, setCopyEventLink] = useState(false);

    const [deletedOverlay, setDeletedOverlay] = useState({
        id: null,
        isPortrait: false,
    });

    const { id: idParam } = useParams();

    const formMethods = useForm({
        defaultValues: values,
        mode: "onChange",
        resolver: yupResolver(updateEventDetailsFormSchema),
    });

    const {
        data: uploadEventOverlayData,
        error: uploadEventOverlayError,
        isError: uploadEventOverlayIsError,
        isLoading: uploadEventOverlayIsLoading,
        isSuccess: uploadEventOverlayIsSuccess,
        mutate: uploadEventOverlayMutate,
    } = useUploadEventOverlayData();

    const {
        error: deleteEventOverlayError,
        isError: deleteEventOverlayIsError,
        isLoading: deleteEventOverlayIsLoading,
        isSuccess: deleteEventOverlayIsSuccess,
        mutate: deleteEventOverlayMutate,
    } = useDeleteEventOverlayData();

    const {
        copyEventLink: copyEventLinkBtnName,
        setAsDefault: setAsDefaultBtnName,
    } = btnsNamesConstants;

    const {
        deleteMsgs: { pending: deletePendingMsg },
        fail: failMsg,
        success: successMsg,
        uploadEventOverlayMsgs: { pending: uploadEventOverlayPendingMsg },
    } = messagesConstants;

    const {
        landscape: landscapeInputLabel,
        portrait: portraitInputLabel,
    } = inputsLabelsConstants;

    const { delete: deleteTooltip } = tooltipsConstants;

    const {
        updateEvent: {
            eventDetails: {
                NoOverlaysNote: noOverlaysNoteConstant,
                eventLink: eventLinkConstant,
                overlaysTitle: overlaysTitleConstant,
                uploadingNote: uploadingNoteConstant,
            },
        },
    } = viewsConstants;

    const {
        control,
        formState: { errors },
        setValue,
    } = formMethods;

    const formData = updateEventFormData.forms.eventDetails;

    const uploadEventOverlayHandler = (file) => {
        setUploadEventOverlayTypeError(false);

        toast.loading(uploadEventOverlayPendingMsg);

        uploadEventOverlayMutate({
            data: getFormData({ photo: file }),
            id: idParam,
        });
    };

    const deleteEventOverlayHandler = (id, isPortrait) => {
        toast.loading(deletePendingMsg);

        setDeletedOverlay({
            id,
            isPortrait,
        });

        deleteEventOverlayMutate(id);
    };

    const changeDefaultOverlayHandler = (id, isPortrait) => {
        if (isPortrait) setDefaultPortraitOverlayId(id);
        else setDefaultLandscapeOverlayId(id);

        setValue(
            "defaultOverlayId",
            id,
            { shouldDirty: true },
        );
    };

    const changeTextFieldHandler = useCallback( // eslint-disable-line
        debounce(
            (value, requestName) => {
                dispatch({
                    payload: {
                        data: {
                            name: requestName,
                            value,
                        },
                    },
                    type: types.CHANGE_EVENT_UPDATE_FORM_FIELD,
                });
            },
            500,
        ),
        [],
    );

    const changeAspectRatioFieldHandler = (e, field, requestName) => {
        const { value } = e.target;

        field.onChange(value);

        const aspectRatioDetails = aspectRatiosData.find((ratio) => ratio.key === value);

        const {
            height: aspectRatioHeight,
            id: aspectRatioId,
            key: aspectRatioKey,
            width: aspectRatioWidth,
        } = aspectRatioDetails;

        dispatch({
            payload: {
                data: {
                    name: requestName,
                    value: {
                        height: aspectRatioHeight,
                        id: aspectRatioId,
                        name: aspectRatioKey,
                        width: aspectRatioWidth,
                    },
                },
            },
            type: types.CHANGE_EVENT_UPDATE_FORM_FIELD,
        });
    };

    const changeDatePickerHandler = (date, field, requestName) => {
        field.onChange(date);

        dispatch({
            payload: {
                data: {
                    name: requestName,
                    value: `${new Date(date).toISOString()}`,
                },
            },
            type: types.CHANGE_EVENT_UPDATE_FORM_FIELD,
        });
    };

    const renderOtherEventDetailsFields = () => (
        <Box
            ml={2}
            mt={2}
            width="90%"
        >
            <Typography
                color="primary"
                component="h4"
                fontWeight="bold"
                mb={2}
            >
                {overlaysTitleConstant}
            </Typography>
            <Box>
                <Box
                    display="flex"
                    mb={5}
                >
                    <Typography
                        mr={2}
                        variant="caption"
                    >
                        {portraitInputLabel}
                    </Typography>
                    {portraitOverlays.length === 0 ? (
                        <Typography
                            color="primary"
                            variant="caption"
                        >
                            {noOverlaysNoteConstant}
                        </Typography>
                    ) : (
                        <Box
                            display="flex"
                            flexWrap="wrap"
                        >
                            {portraitOverlays?.map(({
                                id,
                                overlayUrl,
                            }) => (
                                <Box
                                    key={id}
                                    mb={2}
                                    ml={2}
                                    textAlign="center"
                                >
                                    <ImageListItem>
                                        <img
                                            alt="overlay_img"
                                            loading="lazy"
                                            src={overlayUrl}
                                            style={{
                                                height: "auto",
                                                width: "140px",
                                            }}
                                        />
                                        <ImageListItemBar
                                            actionPosition="left"
                                            position="top"
                                            actionIcon={(
                                                <Tooltip title={deleteTooltip}>
                                                    <IconButton
                                                        disabled={deleteEventOverlayIsLoading || sending}
                                                        onClick={() => deleteEventOverlayHandler(
                                                            id,
                                                            true,
                                                        )}
                                                    >
                                                        <DeleteIcon style={{ color: theme.palette.grey[100] }} />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        />
                                    </ImageListItem>
                                    <FormControlLabel
                                        checked={defaultPortraitOverlayId === id}
                                        control={<Radio />}
                                        label={setAsDefaultBtnName}
                                        value={id}
                                        onChange={() => changeDefaultOverlayHandler(
                                            id,
                                            true,
                                        )}
                                        onInput={() => {
                                            dispatch({
                                                payload: {
                                                    data: {
                                                        name: "defaultOverlayId",
                                                        value: id,
                                                    },
                                                },
                                                type: types.CHANGE_EVENT_UPDATE_FORM_FIELD,
                                            });
                                        }}
                                    />
                                </Box>
                            ))}
                        </Box>
                    )}
                </Box>
                <Box
                    display="flex"
                    mb={5}
                >
                    <Typography
                        mr={2}
                        variant="caption"
                    >
                        {landscapeInputLabel}
                    </Typography>
                    {landscapeOverlays.length === 0 ? (
                        <Typography
                            color="primary"
                            variant="caption"
                        >
                            {noOverlaysNoteConstant}
                        </Typography>
                    ) : (
                        <Box
                            display="flex"
                            flexWrap="wrap"
                        >
                            {landscapeOverlays?.map(({
                                id,
                                overlayUrl,
                            }) => (
                                <Box
                                    key={id}
                                    mb={2}
                                    ml={2}
                                >
                                    <ImageListItem>
                                        <img
                                            alt="overlay_img"
                                            loading="lazy"
                                            src={overlayUrl}
                                            style={{
                                                height: "auto",
                                                width: "140px",
                                            }}
                                        />
                                        <ImageListItemBar
                                            actionPosition="left"
                                            position="top"
                                            actionIcon={(
                                                <Tooltip title={deleteTooltip}>
                                                    <IconButton
                                                        disabled={deleteEventOverlayIsLoading}
                                                        onClick={() => deleteEventOverlayHandler(id)}
                                                    >
                                                        <DeleteIcon style={{ color: theme.palette.grey[100] }} />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        />
                                    </ImageListItem>
                                    <FormControlLabel
                                        checked={defaultLandscapeOverlayId === id}
                                        control={<Radio />}
                                        label={setAsDefaultBtnName}
                                        value={id}
                                        onChange={() => changeDefaultOverlayHandler(id)}
                                        onInput={() => {
                                            dispatch({
                                                payload: {
                                                    data: {
                                                        name: "defaultOverlayId",
                                                        value: id,
                                                    },
                                                },
                                                type: types.CHANGE_EVENT_UPDATE_FORM_FIELD,
                                            });
                                        }}
                                    />
                                </Box>
                            ))}
                        </Box>
                    )}
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    mb={2}
                    width={{
                        md: "580px",
                        xs: "100%",
                    }}
                >
                    <StyledFileUploader error={uploadEventOverlayError || uploadEventOverlayTypeError}>
                        <FileUploader
                            disabled={uploadEventOverlayIsLoading || sending || moduleName === modulesConstants.eventTemplates}
                            handleChange={(file) => uploadEventOverlayHandler(file)}
                            name="file"
                            types={["png", "jpeg", "jpg", "webp"]}
                            onTypeError={() => setUploadEventOverlayTypeError(true)}
                        />
                    </StyledFileUploader>
                    <Typography
                        ml={2}
                        variant="caption"
                    >
                        {uploadingNoteConstant}
                    </Typography>
                </Box>
                <Box
                    alignItems="center"
                    display="flex"
                    flexWrap="wrap"
                    gap={2}
                    justifyContent="space-between"
                >
                    <Box>
                        <Typography
                            color="primary"
                            component="h4"
                            fontWeight="bold"
                            mb={2}
                            mt={5}
                        >
                            {eventLinkConstant}
                        </Typography>
                        <CopyLinkBox
                            copyBtnName={copyEventLinkBtnName}
                            link={window?.__RUNTIME_CONFIG__?.REACT_APP_PUBLIC_PAGE_URL + `event?event=${idParam}`} // eslint-disable-line
                            linkText={`${window?.__RUNTIME_CONFIG__?.REACT_APP_PUBLIC_PAGE_URL}event?event=${idParam?.slice( // eslint-disable-line
                                0,
                                8,
                            )}...`}
                        />
                    </Box>
                    <Box margin="auto">
                        <Link
                            href={window?.__RUNTIME_CONFIG__?.REACT_APP_PUBLIC_PAGE_URL + `event?event=${idParam}`} // eslint-disable-line
                            sx={{ textDecoration: "none" }}
                            target="_blank"
                        >
                            <QRCodeCanvas
                                size={120}
                                value={window?.__RUNTIME_CONFIG__?.REACT_APP_PUBLIC_PAGE_URL + `event?event=${idParam}`} // eslint-disable-line
                                style={{
                                    height: "auto",
                                    maxWidth: "100%",
                                    width: "100%",
                                }}
                            />
                        </Link>
                    </Box>
                </Box>
            </Box>
        </Box>
    );

    const renderFormControllerChildren = (
        field,
        label,
        name,
        type,
        requestName,
    ) => {
        switch (type) {
        case "date":
            return (
                <>
                    <Calendar
                        {...field}
                        disabled={moduleName === modulesConstants.eventTemplates}
                        error={errors?.date}
                        label={label}
                        isEventCalendar
                        onChange={(date) => {
                            changeDatePickerHandler(
                                date,
                                field,
                                requestName,
                            );
                        }}
                    />
                    {errors?.date && (
                        <Typography
                            color="error"
                            component="span"
                        >
                            {errors.date.message}
                        </Typography>
                    ) }
                </>
            );
        case "select":
            return (
                <FormControl fullWidth>
                    <InputLabel>{label}</InputLabel>
                    <Select
                        {...field}
                        label={label}
                        onChange={(e) => {
                            changeAspectRatioFieldHandler(
                                e,
                                field,
                                requestName,
                            );
                        }}
                    >
                        {aspectRatiosData.map(({
                            key,
                            ratio,
                        }) => (
                            <MenuItem
                                key={key}
                                value={key}
                            >
                                {ratio}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            );
        default:
            return (
                <TextField
                    {...field}
                    error={errors[name]}
                    helperText={errors[name] && errors[name]?.message}
                    label={label}
                    variant="outlined"
                    fullWidth
                    onInput={(e) => {
                        changeTextFieldHandler(
                            e.target.value,
                            requestName,
                        );
                    }}
                />
            );
        }
    };

    useEffect(() => {
        const clonedPortraitOverlays = [...portraitOverlays];

        const clonedLandscapeOverlays = [...landscapeOverlays];

        if (values?.overlays) {
            values.overlays.forEach((overlay) => {
                if (overlay.isPortrait) clonedPortraitOverlays.push(overlay);

                if (!overlay.isPortrait) clonedLandscapeOverlays.push(overlay);
            });

            setPortraitOverlays(clonedPortraitOverlays);

            setLandscapeOverlays(clonedLandscapeOverlays);
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        if (values?.overlays) {
            values.overlays.forEach(({
                id,
                isDefault,
                isPortrait,
            }) => {
                if (isPortrait && isDefault) setDefaultPortraitOverlayId(id);

                if (!isPortrait && isDefault) setDefaultLandscapeOverlayId(id);
            });
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        if (uploadEventOverlayIsLoading || deleteEventOverlayIsLoading) dispatch({ type: types.EVENTS_REQUEST });
    }, [uploadEventOverlayIsLoading, deleteEventOverlayIsLoading]); // eslint-disable-line

    useEffect(() => {
        if (uploadEventOverlayIsSuccess) {
            if (uploadEventOverlayData.data.isPortrait && uploadEventOverlayData.data.isDefault) setDefaultPortraitOverlayId(uploadEventOverlayData.data.id);

            if (!uploadEventOverlayData.data.isPortrait && uploadEventOverlayData.data.isDefault) setDefaultLandscapeOverlayId(uploadEventOverlayData.data.id);

            dispatch(uploadEventOverlay(
                uploadEventOverlayData.data,
                successMsg,
            ));

            toast.dismiss();
        }
    }, [uploadEventOverlayIsSuccess]); // eslint-disable-line

    useEffect(() => {
        if (deleteEventOverlayIsSuccess) {
            dispatch(deleteEventOverlay(successMsg));

            toast.dismiss();
        }
    }, [deleteEventOverlayIsSuccess]); // eslint-disable-line

    useEffect(() => {
        if (uploadEventOverlayIsError) {
            dispatch(uploadEventOverlay(
                null,
                failMsg,
                uploadEventOverlayError,
            ));

            toast.dismiss();
        }
    }, [uploadEventOverlayIsError]); // eslint-disable-line

    useEffect(() => {
        if (deleteEventOverlayIsError) {
            dispatch(deleteEventOverlay(
                failMsg,
                deleteEventOverlayError,
            ));

            toast.dismiss();
        }
    }, [deleteEventOverlayIsError]); // eslint-disable-line

    useEffect(() => {
        if (Object.keys(lastUploadedEventOverlay).length > 0 && uploaded) {
            const clonedPortraitOverlays = [...portraitOverlays];

            const clonedLandscapeOverlays = [...landscapeOverlays];

            if (lastUploadedEventOverlay.isPortrait) {
                clonedPortraitOverlays.push(lastUploadedEventOverlay);

                setPortraitOverlays(clonedPortraitOverlays);
            } else {
                clonedLandscapeOverlays.push(lastUploadedEventOverlay);

                setLandscapeOverlays(clonedLandscapeOverlays);
            }
        }
    }, [lastUploadedEventOverlay, uploaded]); // eslint-disable-line

    useEffect(() => {
        const clonedPortraitOverlays = [...portraitOverlays];

        const clonedLandscapeOverlays = [...landscapeOverlays];

        if (deleted) {
            if (deletedOverlay.isPortrait) {
                const filteredPortraitOverlays = clonedPortraitOverlays.filter((overlay) => overlay.id !== deletedOverlay.id);

                setPortraitOverlays(filteredPortraitOverlays);
            } else {
                const filteredLandscapeOverlays = clonedLandscapeOverlays.filter((overlay) => overlay.id !== deletedOverlay.id);

                setLandscapeOverlays(filteredLandscapeOverlays);
            }
        }
    }, [deleted]); // eslint-disable-line

    useEffect(() => {
        if (copyEventLink) {
            setTimeout(() => {
                setCopyEventLink(false);
            }, [3000]); // eslint-disable-line
        }
    }, [copyEventLink]); // eslint-disable-line

    return (
        <FormLayout
            action={action}
            formMethods={formMethods}
            otherFields={renderOtherEventDetailsFields()}
            data={renderFormController(
                control,
                formData,
                renderFormControllerChildren,
            )}
            onSubmitForm={onSubmitForm}
        />
    );
}

export default UpdateEventDetailsForm;
