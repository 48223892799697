import { messagesConstants } from "@constants";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
    activateAdmin,
    activatePhotographer,
    assignEvent,
    deactivateAdmin,
    deactivatePhotographer,
    deleteEvent,
    deleteEventPhoto,
    deleteEventTemplate,
    resendEventPhoto,
    resetAdminPassword,
    resetPhotographerPassword,
    shareEventGallery,
    toggleArchiveEvent,
    toggleEventSlideshowPhoto,
} from "redux/actions";
import {
    activateAdminData,
    activatePhotographerData,
    addEventDomainData,
    addEventPageActionData,
    assignEventData,
    createAdminData,
    createEventData,
    createEventTemplateData,
    createPhotographerData,
    deactivateAdminData,
    deactivatePhotographerData,
    deleteEventData,
    deleteEventDomainData,
    deleteEventOverlayData,
    deleteEventPageActionData,
    deleteEventPhotoData,
    deleteEventTemplateData,
    generateEventZipFolderData,
    getAdminsListData,
    getEventGuestPhotosListData,
    getEventGuestsListData,
    getEventPhotosListData,
    getEventsListData,
    getPhotographersListData,
    postLoginData,
    postLogoutData,
    postSetPasswordData,
    resendEventPhotoData,
    resetAdminPasswordData,
    resetPhotographerPasswordData,
    setDefaultEventOverlayData,
    shareEventGalleryData,
    toggleArchiveEventData,
    toggleEventSlideshowPhotoData,
    updateAdminData,
    updateEventData,
    updateEventTemplateData,
    updatePhotographerData,
    uploadEventBannerImageData,
    uploadEventOverlayData,
} from "requests";

const {
    fail: failMsg,
    success: successMsg,
} = messagesConstants;

export const useLoginData = () => useMutation(postLoginData);

export const useLogoutData = () => useMutation(postLogoutData);

export const useSetPasswordData = () => useMutation(postSetPasswordData);

export const useGetAdminsListData = () => useMutation(getAdminsListData);

export const useCreateAdminData = () => useMutation(createAdminData);

export const useUpdateAdminData = () => useMutation(updateAdminData);

export const useActivateAdminData = () => {
    const dispatch = useDispatch();

    return useMutation(
        activateAdminData,
        {
            onError: (error) => {
                dispatch(activateAdmin(
                    null,
                    failMsg,
                    error,
                ));
            },
            onSuccess: (data) => {
                dispatch(activateAdmin(
                    data.data,
                    successMsg,
                ));
            },
        },
    );
};

export const useDeactivateAdminData = () => {
    const dispatch = useDispatch();

    return useMutation(
        deactivateAdminData,
        {
            onError: (error) => {
                dispatch(deactivateAdmin(
                    null,
                    failMsg,
                    error,
                ));
            },
            onSuccess: (data) => {
                dispatch(deactivateAdmin(
                    data.data,
                    successMsg,
                ));
            },
        },
    );
};

export const useResetAdminPasswordData = () => {
    const dispatch = useDispatch();

    return useMutation(
        resetAdminPasswordData,
        {
            onError: (error) => {
                dispatch(resetAdminPassword(
                    null,
                    failMsg,
                    error,
                ));

                toast.dismiss();
            },
            onSuccess: (data) => {
                dispatch(resetAdminPassword(
                    data.data,
                    successMsg,
                ));

                toast.dismiss();
            },
        },
    );
};

export const useGetPhotographersListData = () => useMutation(getPhotographersListData);

export const useCreatePhotographerData = () => useMutation(createPhotographerData);

export const useUpdatePhotographerData = () => useMutation(updatePhotographerData);

export const useActivatePhotographerData = () => {
    const dispatch = useDispatch();

    return useMutation(
        activatePhotographerData,
        {
            onError: (error) => {
                dispatch(activatePhotographer(
                    null,
                    failMsg,
                    error,
                ));
            },
            onSuccess: (data) => {
                dispatch(activatePhotographer(
                    data.data,
                    successMsg,
                ));
            },
        },
    );
};

export const useDeactivatePhotographerData = () => {
    const dispatch = useDispatch();

    return useMutation(
        deactivatePhotographerData,
        {
            onError: (error) => {
                dispatch(deactivatePhotographer(
                    null,
                    failMsg,
                    error,
                ));
            },
            onSuccess: (data) => {
                dispatch(deactivatePhotographer(
                    data.data,
                    successMsg,
                ));
            },
        },
    );
};

export const useResetPhotographerPasswordData = () => {
    const dispatch = useDispatch();

    return useMutation(
        resetPhotographerPasswordData,
        {
            onError: (error) => {
                dispatch(resetPhotographerPassword(
                    null,
                    failMsg,
                    error,
                ));

                toast.dismiss();
            },
            onSuccess: (data) => {
                dispatch(resetPhotographerPassword(
                    data.data,
                    successMsg,
                ));

                toast.dismiss();
            },
        },
    );
};

export const useGetEventsListData = () => useMutation(getEventsListData);

export const useCreateEventData = () => useMutation(createEventData);

export const useUpdateEventData = () => useMutation(updateEventData);

export const useDeleteEventData = () => {
    const dispatch = useDispatch();

    return useMutation(
        deleteEventData,
        {
            onError: (error) => {
                dispatch(deleteEvent(
                    failMsg,
                    error,
                ));
            },
            onSuccess: () => dispatch(deleteEvent(successMsg)),
        },
    );
};

export const useToggleArchiveEventData = (action) => {
    const dispatch = useDispatch();

    return useMutation(
        toggleArchiveEventData,
        {
            onError: (error) => {
                dispatch(toggleArchiveEvent(
                    failMsg,
                    error,
                    action,
                ));
            },
            onSuccess: () => {
                dispatch(toggleArchiveEvent(
                    successMsg,
                    null,
                    action,
                ));
            },
        },
    );
};

export const useAssignEventData = () => {
    const dispatch = useDispatch();

    return useMutation(
        assignEventData,
        {
            onError: (error) => {
                dispatch(assignEvent(
                    null,
                    failMsg,
                    error,
                ));
            },
            onSuccess: (data) => {
                dispatch(assignEvent(
                    data.data,
                    successMsg,
                ));
            },
        },
    );
};

export const useUploadEventOverlayData = () => useMutation(uploadEventOverlayData);

export const useSetDefaultEventOverlayData = () => useMutation(setDefaultEventOverlayData);

export const useDeleteEventOverlayData = () => useMutation(deleteEventOverlayData);

export const useAddEventDomainData = () => useMutation(addEventDomainData);

export const useDeleteEventDomainData = () => useMutation(deleteEventDomainData);

export const useAddEventPageActionData = () => useMutation(addEventPageActionData);

export const useDeleteEventPageActionData = () => useMutation(deleteEventPageActionData);

export const useUploadEventBannerImageData = () => useMutation(uploadEventBannerImageData);

export const useGetEventGuestsListData = () => useMutation(getEventGuestsListData);

export const useGetEventPhotosListData = () => useMutation(getEventPhotosListData);

export const useResendEventPhotoData = () => {
    const dispatch = useDispatch();

    return useMutation(
        resendEventPhotoData,
        {
            onError: (error) => {
                dispatch(resendEventPhoto(
                    null,
                    failMsg,
                    error,
                ));

                toast.dismiss();
            },
            onSuccess: (data) => {
                dispatch(resendEventPhoto(
                    data.data,
                    successMsg,
                ));

                toast.dismiss();
            },
        },
    );
};

export const useDeleteEventPhotoData = () => {
    const dispatch = useDispatch();

    return useMutation(
        deleteEventPhotoData,
        {
            onError: (error) => {
                dispatch(deleteEventPhoto(
                    failMsg,
                    error,
                ));
            },
            onSuccess: () => dispatch(deleteEventPhoto(successMsg)),
        },
    );
};

export const useShareEventGalleryData = () => {
    const dispatch = useDispatch();

    return useMutation(
        shareEventGalleryData,
        {
            onError: (error) => {
                dispatch(shareEventGallery(
                    failMsg,
                    error,
                ));

                toast.dismiss();
            },
            onSuccess: () => {
                dispatch(shareEventGallery(successMsg));

                toast.dismiss();
            },
        },
    );
};

export const useGenerateEventZipFolderData = () => useMutation(generateEventZipFolderData);

export const useGetEventGuestPhotosListData = () => useMutation(getEventGuestPhotosListData);

export const useToggleEventSlideshowPhotoData = (action) => {
    const dispatch = useDispatch();

    return useMutation(
        toggleEventSlideshowPhotoData,
        {
            onError: (error) => {
                dispatch(toggleEventSlideshowPhoto(
                    failMsg,
                    error,
                    action,
                ));
            },
            onSuccess: () => dispatch(toggleEventSlideshowPhoto(
                successMsg,
                null,
                action,
            )),
        },
    );
};

export const useCreateEventTemplateData = () => useMutation(createEventTemplateData);

export const useUpdateEventTemplateData = () => useMutation(updateEventTemplateData);

export const useDeleteEventTemplateData = () => {
    const dispatch = useDispatch();

    return useMutation(
        deleteEventTemplateData,
        {
            onError: (error) => {
                dispatch(deleteEventTemplate(
                    failMsg,
                    error,
                ));
            },
            onSuccess: () => dispatch(deleteEventTemplate(successMsg)),
        },
    );
};
